<template>
  <el-image :style="{ width: width + 'rem', height: height + 'rem' }" :src="newSrc" :fit="fit"></el-image>
</template>
<script>
import imgUrl from '@/assets/img/photot_student.png'
export default {
  props: {
    width: {
      type: String | Number,
      default: '150'
    },
    height: {
      type: String | Number,
      default: '200'
    },
    fit: {
      type: String,
      default: 'cover'
    },
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    newSrc() {
      return this.src ? this.src : imgUrl
    }
  }
}
</script>
