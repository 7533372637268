<template>
  <el-dialog :title="title" :close-on-click-modal="true" :visible="visible" @close="handleColseDialog">
    <div class="">
      <div class="person-info flex">
        <div class="flex-shrink img mr-6">
          <Photo :src="info.photo"></Photo>
        </div>
        <div class="flex-1 info">
          <div class="top">
            <span class="name">{{ info.student_name }}</span>
            <span class="sex female" v-if="info.gender === '女'"><i class="el-icon-female"></i></span>
            <span class="sex male" v-else><i class="el-icon-male"></i></span>
          </div>
          <el-row class="page-row no-margin">
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">学号：</div>
                <div class="content">{{ info.student_no }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">年龄：</div>
                <div class="content">{{ info.age }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">校区：</div>
                <div class="content">{{ info.school_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">行政班：</div>
                <div class="content">{{ info.class_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">完成时间：</div>
                <div class="content">{{ info.completed_time }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <Card title="登记信息" v-if="info.qa && info.qa.length">
        <div class="my-2 pl-3" v-for="(item, index) in info.qa" :key="item.id">
          <div class="mb-1">
            <span class="text-title">{{ index + 1 }}. {{ item.name }}</span>
            <span class="text-gray">「 {{ qt[item.type] || '数据错误' }} 」</span>
          </div>
          <div class="flex" v-if="[1, 2, 3].includes(item.type)">
            <img class="ansi" :src="$OssAddress + 'A.png'" />
            <div>
              <p class="text-normal" v-for="(c_item, c_index) in item.answer" :key="c_index">
                {{ c_item.name }}
              </p>
            </div>
          </div>
          <div v-else-if="item.type === 4">
            <div class="mt-3 ml-2" v-for="(c_item, c_index) in item.children">
              <p>
                <span class="text-title">{{ index + 1 }}.{{ c_index + 1 }} {{ c_item.name }}</span>
                <span class="text-gray">「 {{ qt[c_item.type] || '数据错误' }} 」</span>
              </p>
              <div class="flex mt-1 justify-start">
                <img class="ansi" :src="$OssAddress + 'A.png'" />
                <div>
                  <p class="text-normal" v-for="(cc_item, cc_index) in c_item.answer" :key="cc_index">
                    <template v-if="[1, 2].includes(c_item.type)">{{ cc_item.name }}</template>
                    <template v-else-if="c_item.type === 3">{{ cc_item }}</template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </el-dialog>
</template>
<script>
import Photo from '../components/Photo.vue'
import Card from '@/components/common/Card.vue'
export default {
  components: { Photo, Card },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleColseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
    }
  },
  data() {
    return {
      student: {},
      qt: {
        1: '单选题',
        2: '多选题',
        3: '填空题',
        4: '矩阵题'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 1100rem !important;
}
.ansi {
  width: 20rem;
  height: 20rem;
  margin-top: 4rem;
  margin-right: 15rem;
  flex-shrink: 0;
}
.text-title {
  font-weight: 600;
}
.text-gray {
  color: #999;
}
</style>
