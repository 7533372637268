<template>
  <div class="page-wrapper white">
    <div class="page-info psychology-page">
      <div class="psychology-h1" v-if="student.student_name">{{ student.student_name }}的咨询记录{{ counsel.is_record ? '（补录）' : '' }}</div>
      <template v-if="[5, 6, 7, 8].includes(counsel.history_status)">
        <div class="status-img"><img :src="`${$OssAddress}s_${[8].includes(counsel.history_status) ? 6 : counsel.history_status}.png`" alt="咨询状态" /></div>
      </template>
      <div class="person-info flex">
        <div class="flex-shrink img mr-6">
          <Photo :src="student.photo"></Photo>
        </div>
        <div class="flex-1 info">
          <div class="top">
            <span class="name">{{ student.student_name }}</span>
            <span class="sex female" v-if="student.student_gender_text === '女'"><i class="el-icon-female"></i></span>
            <span class="sex male" v-else><i class="el-icon-male"></i></span>
          </div>
          <el-row class="page-row no-margin">
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">学号：</div>
                <div class="content">{{ student.student_no }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">行政班：</div>
                <div class="content">{{ student.class_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">年级：</div>
                <div class="content">{{ student.grade_name }}</div>
              </div>
            </el-col>
            <el-col class="item" :sm="12" :md="8">
              <div class="inner">
                <div class="label">校区：</div>
                <div class="content">{{ student.school_name }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="form-padding">
        <Card title="咨询信息">
          <el-row class="page-row w180">
            <el-col class="item">
              <div class="inner">
                <div class="label">发起人</div>
                <div class="content">{{ counsel.create_name }}</div>
              </div>
            </el-col>
            <el-col class="item" v-if="[2, 3, 4].includes(counsel.history_status)">
              <div class="inner">
                <div class="label">本次咨询时间</div>
                <div class="content">{{ counsel.duration }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">本次咨询主题</div>
                <div class="content">{{ counsel.theme }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询次数</div>
                <div class="content">
                  <template v-if="counsel.speed">
                    <a href="javascript:void(0)" @click="handleShowHistory">第 {{ counsel.speed }} 次</a>
                  </template>
                  <span v-else>无</span>
                </div>
              </div>
            </el-col>
            <el-col class="item" v-if="counsel.psychology_status !== 4">
              <div class="inner">
                <div class="label">预约咨询时间</div>
                <div class="content">{{ counsel.subscribe_start_time }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">实际咨询时间</div>
                <div class="content">{{ counsel.start_time }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">咨询地点</div>
                <div class="content">{{ counsel.chamber }}</div>
              </div>
            </el-col>
            <el-col class="item">
              <div class="inner">
                <div class="label">问卷</div>
                <div class="content">
                  <template v-if="counsel.paper">
                    <a href="javascript:void(0)" @click="handleShowDialog">{{ counsel.paper }}</a>
                  </template>
                  <template v-else>无</template>
                </div>
              </div>
            </el-col>
            <template v-if="[2, 3, 4].includes(counsel.history_status)">
              <!-- 测评后心理评估 -->
              <template v-if="counsel.summary_theme_id === 11">
                <div class="form-item-section">第一部分：具体事件、实时心情</div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">1.最近两周的心情怎么样？</div>
                    <div>{{ counsel.summary[0] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>学生的认知和情感</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">2.如果有烦恼，最烦恼的事情是什么？程度如何？已经持续多久了？是否已经严重影响了学习、生活？</div>
                    <div>{{ counsel.summary[1] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>他认为事情：</div>
                    <div>1不严重；2一般严重；3有点严重；4比较严重；5很严重</div>
                    <div>情绪情感：</div>
                    <div>1没烦恼；2一般烦恼；3有点烦恼；4比较烦恼；5烦恼、无法自拔</div>
                  </div>
                </div>
                <div class="form-item-section">第二部分：一般状态</div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">1.觉得生活总体来说是怎样的？未来是否有希望？有些什么打算、理想？</div>
                    <div>{{ counsel.summary[2] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>希望感：</div>
                    <div>1充满希望；2有希望；3一般；4没希望；5令人绝望</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">2.最近一个月，有疲劳感吗？有些什么表现？</div>
                    <div>{{ counsel.summary[3] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>疲劳感：</div>
                    <div>1没有 ；2有时有点；3一般；4一半以上；5总是、非常</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">3.最近一个月，睡眠怎么样？</div>
                    <div>{{ counsel.summary[4] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>睡眠：</div>
                    <div>1 很好；2 较好；3一般；4 不太好；5很差</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">4.最近一个月，学习、作业或者生活方面的压力如何？</div>
                    <div>{{ counsel.summary[5] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>学生的压力：</div>
                    <div>1小、很轻松；2有点；3一般；4较大；5大、受不了</div>
                  </div>
                </div>
                <div class="form-item-section">第三部分：感知与应对方式</div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">1.人们往往会议论别人，也会被人议论，当受到别人的评判时，心里会怎么想？是否会很不安？能否谈一谈想到的这个事情及当时的感受？</div>
                    <div>{{ counsel.summary[6] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>对事情的敏感性：</div>
                    <div>1不敏感；2有点敏感；3一般；4比较敏感；5非常敏感</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">
                      2.人总有不顺心的时候。不如意、郁闷的时候，会做些什么？<br />曾有过以下行为吗？想要大哭、大喊大叫、砸东西、经常坐立不安、想报复/伤害别人、离家出走、觉得死了算了
                    </div>
                    <div>{{ counsel.summary[7] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>冲动性的应对方式：</div>
                    <div>1不冲动；2有点冲动；3一般；4比较冲动；5非常冲动</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">3.在电视、网络上看到自杀事件时，认为可能发生了什么？</div>
                    <div>{{ counsel.summary[8] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>对自杀的态度：</div>
                    <div>1排斥；2有点排斥；3矛盾；4有点接受；5接受</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">
                      4.曾经想到过自杀这件事吗？<br />1从来没有；2偶尔会出现短暂的自杀想法；3有时会出现较长时间的自杀想法；4经常会出现持续的自杀想法；5这个念头整天萦绕在脑中
                    </div>
                    <div>{{ counsel.summary[9] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>自杀意念：</div>
                    <div>1从来没有；2偶尔有；3有时有；4经常有；5一直都有</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">5.曾经想过结束自己生命的方法吗？<br />1没想过；2想过怎样自杀比较好，但没制定出具体细节；3已制订具体计划；4我曾经尝试过自杀的行为</div>
                    <div>{{ counsel.summary[10] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>自杀计划与行为程度：</div>
                    <div>1没有；2 弱；3 较强；4 强</div>
                  </div>
                </div>
                <div class="form-item-section">第四部分：支持系统</div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">1.家里的氛围一般是怎么样的？如不舒服，能否谈一谈具体情况？<br />1温暖 2比较温暖3一般4不太舒服5很不舒服</div>
                    <div>{{ counsel.summary[11] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>【家庭支持】是否充足：</div>
                    <div>1支持充足；2有基本支持；3支持很差</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">2.有好朋友吗？这些朋友是否亲密、要好？<br />1没有朋友2有朋友但是不太亲密3有几个亲密、要好的朋友</div>
                    <div>{{ counsel.summary[12] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>【朋友支持】是否充足：</div>
                    <div>1支持充足；2有基本支持；3支持很差</div>
                  </div>
                </div>
                <div class="form-item-section-content">
                  <div class="flex-1">
                    <div class="form-item-section-title">3.遇到难以解决的困难时，会主动寻求帮助吗？能否详细举例？</div>
                    <div>{{ counsel.summary[13] }}</div>
                  </div>
                  <div class="form-item-section-tips">
                    <div>初步判断：</div>
                    <div>有困难时，主观【感受到的人际支持】：</div>
                    <div>1支持充足；2有基本支持；3支持很差</div>
                  </div>
                </div>
              </template>
              <!-- 首次咨询 -->
              <template v-if="counsel.summary_theme_id === 12">
                <el-col class="item">
                  <div class="inner">
                    <div class="label">测评分数</div>
                    <div class="content">
                      SCL-90的测评结果: {{ counsel.scl }}
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">对来访者的第一印象</div>
                    <div class="content">
                      <div class="gray-box">{{ counsel.summary[0] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">当前问题及来访者已有的应对方式</div>
                    <div class="content">
                      <div class="gray-box">{{ counsel.summary[1] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">求助过程</div>
                    <div class="content">
                      <div class="gray-box">{{ counsel.summary[2] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">家庭关系</div>
                    <div class="content">
                      <div class="gray-box">{{ counsel.summary[3] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">学业表现</div>
                    <div class="content">
                      <div class="gray-box">{{ counsel.summary[4] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">是否存在心理危机</div>
                    <div class="content">
                      <div class="gray-box">{{ levelArr[counsel.summary[5] || 0] }}</div>
                    </div>
                  </div>
                </el-col>
              </template>
              <!-- 危机干预咨询 -->
              <template v-if="counsel.summary_theme_id === 10 || (counsel.summary_theme_id === 12 && counsel.summary[5] > 1 )">
                <el-col class="item">
                  <div class="inner">
                    <div class="label">危机表现</div>
                    <div class="content">
                      <div class="gray-box">{{ content[0] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">个人经历</div>
                    <div class="content">
                      <div class="gray-box">{{ content[1] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">危机风险性因素</div>
                    <div class="content">
                      <div class="gray-box">{{ content[2] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">危机保护性因素</div>
                    <div class="content">
                      <div class="gray-box">{{ content[3] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">干预措施</div>
                    <div class="content">
                      <div class="gray-box">{{ content[4] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">咨询过程</div>
                    <div class="content">
                      <div class="gray-box">{{ content[5] }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="label">转介追踪</div>
                    <div class="content">
                      <div class="gray-box">{{ content[6] }}</div>
                    </div>
                  </div>
                </el-col>
              </template>
              <!-- 其他 -->
              <template v-if="![10, 11, 12].includes(counsel.summary_theme_id)">
                <template v-if="counsel.summary">
                  <el-col class="item">
                    <div class="inner">
                      <div class="label">咨询目标</div>
                      <div class="content">
                        <div class="gray-box">{{ counsel.summary[0] }}</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col class="item">
                    <div class="inner">
                      <div class="label">学生自述</div>
                      <div class="content">
                        <div class="gray-box">{{ counsel.summary[1] }}</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col class="item">
                    <div class="inner">
                      <div class="label">咨询方案</div>
                      <div class="content">
                        <div class="gray-box">{{ counsel.summary[2] }}</div>
                      </div>
                    </div>
                  </el-col>
                </template>
              </template>
              <template v-if="imgs.length">
                <el-col class="item">
                  <div class="inner">
                    <div class="label">上传咨询记录</div>
                  </div>
                </el-col>
                <el-col class="item">
                  <div class="inner">
                    <div class="content">
                      <Uploads :list="imgs" readonly></Uploads>
                    </div>
                  </div>
                </el-col>
              </template>
            </template>
            <el-col class="item">
              <div class="inner">
                <div class="label">是否安排后续咨询</div>
                <div class="content">{{ counsel.is_next ? '是' : '否' }}</div>
              </div>
            </el-col>
            <template v-if="counsel.is_next">
              <el-col class="item">
                <div class="inner">
                  <div class="label">下次咨询时间</div>
                  <div class="content">{{ counsel.next_subscribe_start_time }}</div>
                </div>
              </el-col>
              <el-col class="item">
                <div class="inner">
                  <div class="label">下次咨询地点</div>
                  <div class="content">{{ counsel.next_chamber }}</div>
                </div>
              </el-col>
            </template>
            <el-col class="item" v-if="[5, 6, 7].includes(counsel.history_status)">
              <div class="inner">
                <div class="label">{{ `${counsel.history_status === 5 ? '拒绝' : '取消'}原因` }}</div>
                <div class="content">{{ counsel.refuse_remark }}</div>
              </div>
            </el-col>
          </el-row>
        </Card>
      </div>
      <History :visible.sync="isShowHistory" :group_id="counsel.group_id" :id="id"></History>
      <Paper :visible.sync="isVisibleDialog" title="问卷详情" :info="info"></Paper>
    </div>
    <div class="page-tool border">
      <el-button :type="[1, 5, 6, 7].includes(counsel.history_status) ? 'primary' : ''" @click="$router.back()">返回</el-button>
      <el-button v-if="[2, 3].includes(counsel.history_status)" type="primary" @click="handlePath(0)">结案</el-button>
      <el-button v-else-if="counsel.history_status === 4" type="primary" @click="handlePath(1)">结案详情</el-button>
    </div>
  </div>
</template>
<script>
import History from '../components/History.vue'
import Photo from '../components/Photo.vue'
import Card from '@/components/common/Card.vue'
import Paper from '../components/Paper'
import Uploads from '@/components/common/Uploads.vue'
import { getApi2 } from '@/api'
export default {
	_config:{"route":{"path":"detail","meta":{"title":"详情"}}},
  components: { History, Card, Photo, Paper, Uploads },
  data() {
    return {
      id: 0,
      student: {},
      counsel: {},
      content: [],
      imgs: [],
      levelArr: ['否', '一类', '二类', '三类'],
      info: {},
      isVisibleDialog: false,
      isShowHistory: false
    }
  },
  created() {
    this.id = +this.$route.query.id
    this.getDetail()
  },
  watch: {
    '$route.query.id'(val) {
      this.id = +val
      this.init()
    }
  },
  methods: {
    init() {
      this.student = {}
      this.counsel = {}
      this.getDetail()
    },
    getDetail() {
      getApi2('/evaluation/sum-up/details', { id: this.id }).then(res => {
        const { student, counsel } = res
        if (student) {
          this.student = student
        }
        if (counsel) {
          const { summary, summary_theme_id, imgs } = counsel
          this.counsel = counsel
          if(imgs) {
            this.imgs = imgs.split(',').map(src => ({ src }))
          }
          if(summary_theme_id === 10) {
            this.content = summary
          }
          if(summary_theme_id === 12 && summary[5] > 1) {
            this.content = summary.slice(6)
          }
        }
      })
    },
    handleShowDialog(id) {
      getApi2('/evaluation/sum-up/questionnaire', { id: this.id }).then(res => {
        this.info = res
      })
      this.isVisibleDialog = true
    },
    handleShowHistory() {
      this.isShowHistory = true
    },
    handlePath(type) {
      this.$router.push(`/edu/psychology/case/${type ? 'detail' : 'add'}?${type ? `id=${this.counsel.group_id}` : `id=${this.counsel.id}&group_id=${this.counsel.group_id}${this.counsel.group_draft ? '&edit=1' : ''}`}`)
    }
  }
}
</script>
<style lang="scss" scoped></style>
