<template>
  <el-dialog title="历史咨询记录" :close-on-click-modal="true" :visible="visible" @close="handleColseDialog">
    <el-table :data="tableData" style="width: 100%" size="small" v-loading="loading" :stripe="false" empty-text="暂无历史记录">
      <el-table-column type="index" label="序号" width="60rem"> </el-table-column>
      <el-table-column prop="start_at" label="咨询时间"> </el-table-column>
      <el-table-column prop="chamber" label="咨询地点"> </el-table-column>
      <el-table-column prop="theme" label="咨询主题"> </el-table-column>
      <el-table-column prop="score" label="操作" width="120rem">
        <template slot-scope="scope">
          <el-button size="mini" @click="handlePath(scope.row.id)" :disabled="scope.row.id === id">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pb-2"></div>
    <el-pagination :current-page="page" :page-size="pageSize" :total="total" background layout="total, prev, pager, next,jumper" @current-change="handleCurrentChange"> </el-pagination>
  </el-dialog>
</template>

<script>
import { tableList } from '@/api'
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    group_id: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: {},
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 20
    }
  },
  watch: {
    group_id(val) {
      if (val) this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      if (!this.group_id) return
      this.getDataApi('/api2/api/evaluation/sum-up/history', { group_id: this.group_id })
    },
    getDataApi(api, paramsConfig = {}) {
      let params = {
        page: this.page,
        ...this.search,
        ...paramsConfig
      }
      this.loading = true
      tableList(api, params)
        .then(res => {
          const { list, pageCount, total } = res
          this.total = total
          this.pageCount = pageCount
          this.tableData = list
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getData && this.getData()
    },
    handleColseDialog() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    handlePath(id) {
      this.$router.push(`/edu/psychology/records/detail?id=${id}`)
      this.handleColseDialog()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 1000rem !important;
}
</style>
