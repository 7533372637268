<!--
 * @FileDescription: 自定义多文件上传组件
 * @Author: 朱建波
 * @Date: 2023-09-28
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZUploads
 *
 * @Props
 * @property {Array<Object>} list          传入的值 例：[{ src: 'xxxxx' }]
 * @property {String} keyName              自定义显示图片key
 * @property {String} layout               布局方式 grid flex
 * @property {String|Number} code          自定义成功状态码
 * @property {String} tips                 自定义提示内容
 * @property {Number} limit                限制上传图片个数
 * @property {Number} size                 限制上传单个图片的大小(M)
 * @property {Number} accept               限制上传文件类型 image/*
 * @property {String} name                 后端定义的name
 * @property {Object} data                 额外添加上传数据
 * @property {Number} data.type            上传类型  1 'images'公共图片,2 'bucket_template' 模版,3 'recruit-image' 招生
 * @property {Number} data.use_outer_url   携带带域名  1 携带 0 不携带
 * @property {String} text                 自定义新增按钮文字
 * @property {String|Number} width         自定义宽度
 * @property {String|Number} height        自定义高度
 * @property {Boolean} autoUpload          是否自动上传
 * @property {Boolean} readonly            是否只读
 * @property {Boolean} showImg             是否全屏展示图片
 * @property {Function} beforeUpload       自定义上传前置函数
 * @property {Function} onSuccess          自定义上传成功函数
 * @property {Function} onError            自定义上传失败函数
 *
 * @Slots
 *
 * @Methods
 * change 列表数据改变钮触发事件  全部 status 为 uploaded/success 时 全部上传完成
-->
<script>
import { fileUpload } from '@/api'
export default {
  name: 'ZUploads',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    keyName: {
      type: String,
      default: 'src'
    },
    layout: {
      type: String,
      default: 'flex'
    },
    code: {
      type: [String, Number],
      default: 0
    },
    tips: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 3
    },
    size: {
      type: Number,
      default: 10
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png'
    },
    name: {
      type: String,
      default: 'upload'
    },
    data: {
      type: Object,
      default: () => ({ type: 1, use_outer_url: 1 })
    },
    text: {
      type: String,
      default: '上传图片'
    },
    width: {
      type: [String, Number],
      default: '180'
    },
    height: {
      type: [String, Number],
      default: '180'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showImg: {
      type: Boolean,
      default: true
    },
    beforeUpload: {
      type: Function
    },
    onSuccess: {
      type: Function
    },
    onError: {
      type: Function
    }
    // httpRequest: {
    //   type: Function
    // }
  },
  data() {
    return {
      uploadList: [],
      isDelete: false,
      dropHover: false
    }
  },
  computed: {
    previewList() {
      if(!this.showImg) return []
      return this.uploadList.map(item => item.showUrl || item[this.keyName])
    },
    boxStyle() {
      return {
        width: this.width +'rem',
        height: this.height +'rem'
      }
    },
    layoutStyle() {
      if(this.layout === 'grid') {
        return {
          gridTemplateColumns: `repeat(auto-fill, ${this.width}rem)`
        }
      }
      return ''
    },
    isCanUpload() {
      return this.uploadList.every(item => ['success', 'uploaded', 'uploading', 'deleting'].includes(item.status))
    },
    filterList() {
      return this.transFormList(this.uploadList)
    }
  },
  watch: {
    list: {
      handler(val) {
        this.uploadList = this.transFormList(val)
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    this.uploadList.map(item => {
      item.showUrl && item.status !== 'uploaded' && URL.revokeObjectURL(item.showUrl)
    })
  },
  created() {
    if(Object.prototype.toString.call(this.list) !== `[object Array]`) {
      console.warn('ZUploads组件的传入值list必须是数组')
    }
  },
  methods: {
    // 转化数据
    transFormList(arr) {
      // status 状态
      // 1.ready 等待上传 2.uploading 上传中 3.deleting 删除中  4.success 上传成功  5.error 上传失败 6.canceled 取消上传 7.uploaded 已上传
      if(Object.prototype.toString.call(this.list) !== `[object Array]`) {
        return []
      }
      return arr.map(item => {
        let obj = {
          name: item.name || '--',
          size: item.size || '--'
        }
        item.file && (obj.file = item.file)
        item.status && (obj.status = item.status)
        item.response && (obj.response = item.response)
        item.uuid && (obj.uuid = item.uuid)
        item.showUrl && (obj.showUrl = item.showUrl)
        item[this.keyName] && ((obj[this.keyName] = item[this.keyName]), (obj.status = 'uploaded'))
        return obj
      })
    },
    // 大小转化
    bytesToSize(bytes) {
      if (bytes === 0) return '0 B'
      let k = 1024,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k))
      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
    },
    getUuid(){
      if (typeof crypto === 'object') {
        if (typeof crypto.randomUUID === 'function') {
          return crypto.randomUUID()
        }
        if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
          const callback = (c) => {
            const num = Number(c)
            return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16)
          }
          return `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[018]/g, callback)
        }
      }
      let timestamp = new Date().getTime()
      let performNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let random = Math.random() * 16
        if (timestamp > 0) {
          random = (timestamp + random) % 16 | 0
          timestamp = Math.floor(timestamp / 16)
        } else {
          random = (performNow + random) % 16 | 0
          performNow = Math.floor(performNow / 16)
        }
        return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
      })
    },
    showTips(message) {
      this.$message({ type: 'warning', duration: 2000, message })
    },
    // 判断上传最大数
    handleJudge(num = 0) {
      if (this.limit < this.uploadList.length + num) {
        this.showTips('已超过最大上传数！')
        return false
      }
      return true
    },
    handleChangeList() {
      this.$emit('change', [...this.filterList])
    },
    // 选择图片
    handleChoose() {
      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))
    },
    //获取选择文件
    handleChangeFile(event){
      this.handleFiles(event.target.files)
    },
    handleFiles(files) {
      const len = files.length
      let isLimit = this.handleJudge(len)
      if (!isLimit) return (this.$refs.fileRef.value = '')
      const acceptArr = this.accept ? this.accept.split(',') : ''
      for (let i = 0; i < len; i++) {
        // 上传前验证
        let isSizeOk = true, isTypeOk= true;
        const size = files[i].size / 1024 / 1024
        if (this.beforeUpload) {
          isSizeOk = this.beforeUpload(files[i])
        } else {
          if(this.size < size) {
            isSizeOk = false
          }
          if(acceptArr && !acceptArr.includes(files[i].type)) {
            isTypeOk = false
          }
        }
        if(!isTypeOk) {
          this.showTips(`${files[i].name}格式不正确，仅支持${ this.accept}！`)
          continue
        }
        if(!isSizeOk) {
          if(!this.beforeUpload) {
            this.showTips(`${files[i].name}已超过${this.size}M！`)
          }
          continue
        }
        // 合格文件执行
        const uuid = this.getUuid()
        files[i].uuid = uuid
        const controller = new AbortController()
        const blob = new Blob([files[i]], { type: files[i].type });
        const item = {
          file: files[i],
          name: files[i].name,
          size: this.bytesToSize(files[i].size),
          progress: 0,
          status: 'ready',
          uuid,
          controller,
          showUrl: URL.createObjectURL(blob)
        }
        this.uploadList.push(item)
        if(this.autoUpload) {
          this.handleUpload(item)
        }
        // const fileReader = new FileReader()
        // fileReader.readAsDataURL(files[i])
        // fileReader.onload = function(){
        //   item.url = fileReader.result
        // }
      }
      this.$refs.fileRef.value = ''
    },
    // 自定义延时
    sleep(delay = 300) {
      return new Promise(resolve => setTimeout(() => resolve(true) , delay))
    },
    // 删除图片
    handleDelete(index, item) {
      let that = this
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose(action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            that.isDelete = true
            const { showUrl, status } = item
            const obj = { ...item, status: 'deleting' }
            showUrl && status !== 'uploaded' && URL.revokeObjectURL(showUrl)
            that.uploadList.splice(index, 1, obj)
            that.handleChangeList()
            window.setTimeout(() => {
              that.handleCancel(item)
              that.uploadList.splice(index, 1)
              that.isDelete = false
              that.handleChangeList()
              done()
              instance.confirmButtonText = '确定'
              instance.confirmButtonLoading = false
            }, 300)
          } else {
            done()
          }
        }
      }).then(() => {
        this.$message({
          type: 'success',
          message: `删除${item.name}成功!`
        })
      })
    },
    // 取消上传
    handleCancel(row) {
      if (row.controller) {
        row.controller.abort()
        row.controller = null
        // 非删除时改变状态
        if (!this.isDelete) {
          this.handleChangeList()
        }
      }
    },
    // 单个上传 重新上传
    handleUpload(row) {
      // 已上传/展示文件/删除中/上传中
      if(['success', 'uploaded', 'deleting', 'uploading'].includes(row.status)) {
        return
      }
      // 添加取消
      if (!row.controller) {
        row.controller = new AbortController()
      }
      row.status = 'uploading'
      this.handleUploadRequest(row, params => {
        this.showProgress(row, params)
      })
    },
    // 上传全部
    handleSubmit() {
      this.uploadList.forEach(row => {
        this.handleUpload(row)
      })
    },
    // 展示上传进度
    showProgress(row, params) {
      const { progress, status, response } = params
      this.uploadList.forEach(item => {
        if (item.uuid === row.uuid) {
          item.progress = progress
          item.status = status
          item.response = response
        }
      })
    },
    // 请求
    async handleUploadRequest(row, callback) {
      let progress = 0
      let formData = new FormData()
      formData.append(this.name, row.file)
      Object.keys(this.data).forEach(key => {
        formData.append(key, this.data[key])
      })
      fileUpload('/api2/api/site/upload', formData, { callback, controller: row.controller, origin: 1 }).then(response => {
        if (this.onSuccess) {
          this.onSuccess(response)
        }
        const { status, msg } = response
        // 自定义成功状态
        if(Number(this.code) === Number(status)) {
          // 成功状态
          row.controller = null
          callback({ progress: 100, status: 'success', response })
        } else {
          this.$message.error(msg)
          callback({ progress, status: 'error', response })
        }
        this.handleChangeList()

      })
      .catch(err => {
        if (this.onError) {
          this.onError(err)
        }
        // 取消状态
        if (err?.message === 'canceled') {
          callback({ progress, status: 'canceled' })
          this.handleChangeList()
          return
        }
        // 失败状态
        callback({ progress, status: 'error', response: err })
        this.handleChangeList()
      })
    },
    // 展示进度文字信息
    showProgressText(progress) {
      if(typeof progress === 'number') {
        return progress + '%'
      }
      return progress
    },
    // 拖拽上传事件
    stopEvent(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleEnter(e) {
      this.dropHover = true
    },
    handleLeave(e) {
      this.dropHover = false
    },
    handleOver(e) {
      this.stopEvent(e)
    },
    handleDrop(e) {
      this.stopEvent(e)
      this.dropHover = false
      this.handleFiles(e.dataTransfer.files)
    },
  }
}
</script>

<template>
  <div class="upload-wrapper" :class="[{ readonly }]">
    <div class="upload-button" v-if="!autoUpload">
      <el-button type="primary" size="small" :disabled="isCanUpload" @click="handleSubmit">开始上传</el-button>
    </div>
    <div class="upload-inner" :class="[`${layout}-wrapper`, { readonly }]" :style="[layoutStyle]">
      <div class="flex-item upload-item" :style="[boxStyle]" v-for="(item, index) in uploadList" :key="item.uuid">
        <div class="upload-status" v-if="!readonly">
          <el-tag type="info" v-if="item.status === 'ready'">等待上传！</el-tag>
          <el-tag type="success" v-else-if="item.status === 'success'">上传成功！</el-tag>
          <el-tag type="danger" v-else-if="item.status === 'error'">上传失败！</el-tag>
          <el-tag type="warning" v-else-if="item.status === 'canceled'">取消上传！</el-tag>
          <el-tag type="success" v-else-if="item.status === 'uploaded'">已上传！</el-tag>
        </div>
        <div v-if="!readonly" class="upload-icon close" @click.stop="handleDelete(index, item)"></div>
        <div class="upload-icon loading" :loading="showProgressText(item.progress)" v-if="item.status === 'uploading'">
          <el-button type="warning" icon="el-icon-close" size="small" @click.stop="handleCancel(item)">取消上传</el-button>
        </div>
        <div class="upload-icon delete" v-else-if="item.status === 'deleting'"></div>
        <div class="upload-icon error" v-else-if="['error', 'canceled'].includes(item.status)">
          <el-button type="primary" icon="el-icon-refresh" size="small" @click.stop="handleUpload(item)">重新上传</el-button>
        </div>
        <el-image class="upload-image" :style="[boxStyle]" :src="item.showUrl || item[keyName]" :preview-src-list="previewList" fit="scale-down"></el-image>
      </div>
      <div class="upload-add" :class="[dropHover ? 'hover' : '']" draggable="true" :style="[boxStyle]" v-if="uploadList.length < limit && !readonly" @click="handleChoose" @dragenter="handleEnter" @dragleave="handleLeave" @dragover="handleOver" @drop="handleDrop">
        <div class="upload-icon add"></div>
        <div class="upload-text">{{ text }}</div>
      </div>
      <!-- 调试时使用 -->
      <!-- <div class="flex-item upload-item">
        <div class="upload-icon close"></div>
        <div class="upload-icon error">
          <el-button type="primary" icon="el-icon-refresh" size="small">重新上传</el-button>
        </div>
        <el-image :style="boxStyle" mode="aspectFill"></el-image>
      </div>
      <div class="flex-item upload-item">
        <div class="upload-icon loading" loading="86">
          <el-button type="warning" icon="el-icon-close" size="small">取消上传</el-button>
        </div>
        <el-image :style="boxStyle" mode="aspectFill"></el-image>
      </div>
      <div class="flex-item upload-item">
        <div class="upload-icon delete"></div>
        <el-image :style="boxStyle" mode="aspectFill"></el-image>
      </div> -->
    </div>
    <div class="upload-hidden">
      <input ref="fileRef" type="file" placeholder="请选择上传文件" @change="handleChangeFile" :multiple="limit === 1 ? false : true" :accept="accept" />
    </div>
    <div class="upload-tips" v-if="!readonly">{{ tips || `请上传小于${size}MB的png/jpg格式的图片` }}</div>
  </div>
</template>



<style lang="scss" scoped>
.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20rem;
  .upload-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.grid-wrapper {
  display: grid;
  grid-gap: 20rem;
  // grid-template-columns: repeat(auto-fill, 220rem);
  .upload-item {
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
  }
}
.upload {
  &-wrapper {
    // padding: 20rem;
    &.readonly {
      .upload-icon {
        display: none;
      }
      .upload-item {
        &:hover {
          border-color: #d9d9d9;
        }
      }
    }
  }
  &-hidden {
    display: none;
  }
  &-button {
    padding-bottom: 10rem;
  }
  &-tips {
    padding: 10rem 0;
    font-size: 14rem;
    color: #8c939d
  }
  &-status {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-item {
    overflow: hidden;
    position: relative;
    width: 180rem;
    height: 180rem;
    border-radius: 6rem;
    border: solid 1rem #d9d9d9;
    &:hover {
      border-color: #1d2088;
      .error {
        display: flex;
      }
      .loading {
        &::after {
          content: none;
        }
        button {
          display: inline-block;
        }
      }
    }
  }
  &-add {
    width: 180rem;
    height: 180rem;
    border: 1rem dashed #d9d9d9;
    border-radius: 6rem;
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    &:hover, &.hover {
      border-color: #1d2088;
      .upload-icon.add {
        &::before, &::after {
          background: #1d2088;
        }
      }
      .upload-text {
        color: #1d2088;
      }
    }
  }
  &-image {
    width: 180rem;
    height: 180rem;
  }
  &-icon {
    position: relative;
    &::before, &::after {
      position: absolute;
      content: '';
      font-size: 24rem;
      color: #fff;
    }
    &.close {
      position: absolute;
      z-index: 10;
      top: 6rem;
      right: 6rem;
      width: 30rem;
      height: 0;
      padding-bottom: 30rem;
      background: rgba(0, 0, 0, 0.5);
      transform: rotate(45deg);
      border-radius: 50%;
      cursor: pointer;
      &::before, &::after {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: #fff;
      }
      &::before {
        width: 2rem;
        height: 50%;
      }
      &::after {
        width: 50%;
        height: 2rem;
      }
      &:hover {
        background-color: #f56c6c;
      }
    }
    &.loading, &.error, &.delete {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
    }
    &.loading, &.delete {
      &::before {
        top: 20%;
        width: 60rem;
        height: 60rem;
        background: rgba(0,0,0,0) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjAiIGhlaWdodD0iMTIwIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMDB2MTAwSDB6Ii8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTlFOUU5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTMwKSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iIzk4OTY5NyIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMCAxMDUuOTggNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjOUI5OTlBIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDYwIDc1Ljk4IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0EzQTFBMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCA2NSA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNBQkE5QUEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoMTIwIDU4LjY2IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0IyQjJCMiIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgxNTAgNTQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjQkFCOEI5IiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKDE4MCA1MCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDMkMwQzEiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE1MCA0NS45OCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNDQkNCQ0IiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEyMCA0MS4zNCA2NSkiLz48cmVjdCB3aWR0aD0iNyIgaGVpZ2h0PSIyMCIgeD0iNDYuNSIgeT0iNDAiIGZpbGw9IiNEMkQyRDIiIHJ4PSI1IiByeT0iNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDM1IDY1KSIvPjxyZWN0IHdpZHRoPSI3IiBoZWlnaHQ9IjIwIiB4PSI0Ni41IiB5PSI0MCIgZmlsbD0iI0RBREFEQSIgcng9IjUiIHJ5PSI1IiB0cmFuc2Zvcm09InJvdGF0ZSgtNjAgMjQuMDIgNjUpIi8+PHJlY3Qgd2lkdGg9IjciIGhlaWdodD0iMjAiIHg9IjQ2LjUiIHk9IjQwIiBmaWxsPSIjRTJFMkUyIiByeD0iNSIgcnk9IjUiIHRyYW5zZm9ybT0icm90YXRlKC0zMCAtNS45OCA2NSkiLz48L3N2Zz4=') no-repeat center / cover;
        animation: z-loading 1s steps(12) infinite;
      }
      &::after {
        top: calc(20% + 60rem);
        font-size: 24rem;
        line-height: 40rem;
      }
      button {
        display: none;
        margin-top: 60rem;
      }
    }
    &.loading {
      &::after {
        // content: attr(loading) '%';
        content: attr(loading);
      }
    }
    &.delete {
      &::after {
        content: '删除中';
      }
    }
    &.error {
      display: none;
    }
    &.add {
      pointer-events: none;
      width: 80%;
      height: 0;
      padding-bottom: 80%;
      margin-bottom: 4rem;
      &::before, &::after {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: #8c939d;
      }
      &::before {
        width: 2rem;
        height: 50%;
      }
      &::after {
        width: 50%;
        height: 2rem;
      }
    }
  }
  &-text {
    pointer-events: none;
    font-size: 16rem;
    color: #999;
  }
}
@keyframes z-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
