<script>
export default {
  name: 'ZCard',
  props: {
    title: String,
    titleCss: Object
  }
}
</script>

<template>
  <div class="z-card">
    <div class="z-card-title">
      <slot name="header">
        <div class="title-text">
          <span :style="titleCss">{{ title }}</span>
        </div>
        <slot name="right"></slot>
      </slot>
    </div>
    <div class="z-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.z-card {
  &:not(:last-child) {
    margin-bottom: 80rem;
  }
  &-title {
    height: 32rem;
    margin-bottom: 15rem;
    font-size: 16rem;
    font-weight: 500;
    line-height: 32rem;
    border-top: 1rem solid #e8edff;
    display: flex;
    justify-content: space-between;
    .title-text {
      padding: 0 38rem;
      color: #1d2088;
      background-color: #e8edff;
      border-radius: 0 0 4rem 4rem;
    }
  }
  &-content {
    padding: 10rem;
    font-size: 14rem;
    font-weight: 400;
    color: #333;
    line-height: 28rem;
  }
}
</style>
